import React from "react";
import productsData from './productsData'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";

const Products = () => {

  const products = productsData.map((product) => {

    return (
      <>

        <Col xs={12} md={4}>
          <Col className="p-r-5 p-l-5">
            <Row key={product.id} className="text-start row mt-3 mb-3 card p-b-15 p-t-15">
              <Col xs={12} className="m-b-20">
                <img src={product.blogImg} alt={product.img} className="img-fluid BlogIm" />
              </Col>
              <Col xs={12}>
                <h5 className="font-bold">{product.dataHead.length > 37 ? `${product.dataHead.substring(0, 35)}...` : product.dataHead}</h5>
                <p style={{ color: "#9d9d9d" }}>Posted at {product.dataDate}</p>
                <p>{product.data.length > 150 ? `${product.data.substring(0, 150)}...` : product.data}</p>
                <Link className="btn-sm btn btn-success" to={`/News/${product.dataHead.replace(/\s/g, "-").toLowerCase()}`}>Read More</Link>
              </Col>
            </Row>
          </Col>
        </Col>




      </>

    );
  });

  return (
    <>

      <Container className="mt-5 mb-5">
        <Row gap={4}>
          {products}
        </Row>
      </Container>
    </>
  );
};

export default Products;
