import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CustomCss/CustomStyle.css";
import { Row, Container, Col } from "react-bootstrap";
import DnvNau1 from '../Images/TypeApp/DNV-certificate-NAU-1-scaled.jpg'
import DnvNau2 from '../Images/TypeApp/DNV-certificate-NAU-2-scaled.jpg'
import Sfa1 from '../Images/TypeApp/SFA-A1182688-THKN-1-scaled.jpg'
import Sfa2 from '../Images/TypeApp/SFA-A1182688-THKN-2-scaled.jpg'
import Sfa3 from '../Images/TypeApp/SFA-A1182688-THKN-3-scaled.jpg'
import Sfa4 from '../Images/TypeApp/SFA-A1182688-THKN-4-scaled.jpg'
import Sfa5 from '../Images/TypeApp/SFA-A1182688-THKN-5-scaled.jpg'
import Sfa6 from '../Images/TypeApp/SFA-A1182688-THKN-6-scaled.jpg'
import Sfa7 from '../Images/TypeApp/SFA-A1182688-THKN-7-scaled.jpg'
import Sfa8 from '../Images/TypeApp/SFA-A1182688-THKN-8-1-scaled.jpg'
import Sfa9 from '../Images/TypeApp/SFA-A1182688-THKN-9-scaled.jpg'
import AppDnv1 from '../Images/TypeApp/Approval-DNV-GL-Orion-1.jpg'
import AppDnv2 from '../Images/TypeApp/Approval-DNV-GL-Orion-2.jpg'
import SEO from '../Components/Seo/Seo';
import { HelmetProvider } from 'react-helmet-async';
export default function TypeApproved() {
    const TypeAppr = [{
        id: 1,
        TI: DnvNau1
    },
    {
        id: 2,
        TI: DnvNau2
    },
    {
        id: 3,
        TI: Sfa1
    },
    {
        id: 4,
        TI: Sfa2
    },
    {
        id: 5,
        TI: Sfa3
    },
    {
        id: 6,
        TI: Sfa4
    },
    {
        id: 7,
        TI: Sfa5
    },
    {
        id: 8,
        TI: Sfa6
    },
    {
        id: 9,
        TI: Sfa7
    },
    {
        id: 10,
        TI: Sfa8
    },
    {
        id: 11,
        TI: Sfa9
    },
    {
        id: 12,
        TI: AppDnv1
    }, {
        id: 13,
        TI: AppDnv2
    }]
    return (
        <>

            <HelmetProvider>
                <SEO
                    description="Explore NAU 2.1, the cutting-edge maritime ERP software designed to enhance safety, streamline crew management, and optimize fleet performance. Our solution offers intelligent modules for safety documentation, audits, crew selection, defect reporting, certificate management, risk assessment, and more. Elevate your maritime operations with NAU 2.1's centralized filing system, administration control, and seamless integration with third-party services. Unlock efficiency, transparency, and reliability in your maritime endeavors. Orion Marine Concepts is one of the leading Maritime Software Companies, offering Marine Software Solutions, Marine Waste Management System, VDR Analysis & Assessment Software"
                    title="Orion Marine Concepts | Type Approved"
                    keywords="Maritime ERP, Ship Management Software, Maritime Compliance, Fleet Management, Business Intelligence, Green Shipping SolutionsMaritime ERP, Cloud-Based Software, Safety Management, Crew Optimization, Fleet Performance, Certificate Management, Risk Assessment, Centralized Filing System, Third-Party Integration, NAU 2.1. Marine Software solutions, Electronic Marpol Seal Log Marine, Waste Management Systems, Vdr Analysis and Assesment Software, Maritime Software, Maritime Software Solutions, Maritime Software Companies" />
            </HelmetProvider>

            <Row className="m-r-0 m-l-0">
                <Container className="m-t-100">
                    <Row>
                        <Col>
                            <h1>
                                Certifications
                            </h1>
                        </Col>
                    </Row>
                    <Row className="m-t-50">
                        {
                            TypeAppr.map(data => (
                                <Col key={data.id} xs={12} md={6} className="p-l-5 p-r-5 TypeApproved">
                                    <img alt="" src={data.TI} className="img-fluid m-b-40" />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </Row>
        </>
    )
}