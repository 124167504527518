import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ConstructionBg from '../Images/BgCounstruction.jpg';
import '../CustomCss/HFHide.css'
import { motion } from 'framer-motion';

export default function UnderCounstruction() {

    return (

        <>
            <Row style={{ backgroundImage: `url(${ConstructionBg})` }} className='comingSoon'>
                <Container className='container_Com'>
                    <Col>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.2 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            animate={{}}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}>
                            <h1 className='h1_Com'>
                                We are preparing something exciting & amazing for you.
                            </h1>
                        </motion.div>
                    </Col>
                </Container>

            </Row>


        </>
    )
}