import NewsBg from '../Images/NewsBg.jpg';
import Products from '../Components/BlogComponents/Products';
import SEO from '../Components/Seo/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';
function News(index) {
    let boxVariantsL = {};
    const isMobile = window.innerWidth < 1100;
    if (!isMobile) {
      boxVariantsL = {
        initial: { opacity: 0, x: index % 2 === 0 ? 350 : -350 },
        whileInView: { opacity: 1, x: 0, transition: { duration: 0.7 } }
      }
   
    }
    else {
      boxVariantsL = {
        initial: { opacity: 1 },
        whileInView: { opacity: 1 }
      }
   
    }
    return (<>

        <HelmetProvider>
            <SEO
                description="Orion Marine Concepts is one of the leading Maritime Software Companies, offering Maritime Software, Marine Waste Management System, VDR Analysis & Assesment Software"
                title="Orion Marine Concepts | Latest News"
                keywords="Electronic Marpol Seal Log, Marine Waste Management Systems, Vdr Analysis and Assesment, software, Maritime Software, Maritime Software Companies, Ship Crew Management System, Marine Crew Management Services, Fleet Management System, Ship Fleet Management System, Electronic Document Management System, Performance Monitoring Software, Marine Performance Management System, Vessel Performance Monitoring, Ship Performance Monitoring, Inventory Management System, Marine Risk Management Software, Risk Assessment for Ships, Ship Safety Management System, Kpi Software, Global Ship Tracking Intelligence, Ship Monitoring System, Shipping Management Software" />
        </HelmetProvider>

        <Row className='m-l-0 m-r-0 p-l-0 p-r-0'>
            <Container fluid className="p-l-0 p-r-0 backgroundTtachment" style={{ backgroundImage: `url(${NewsBg})` }}>
                <Row className='m-l-0 m-r-0 motion-container'>
                    <Col xs={12} md={6}>
                        <motion.div variants={boxVariantsL} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                            <h1 className="BiEmpowering" style={{ color: "#ffffff" }}>BLOG / NEWS</h1>
                        
                        </motion.div>
                    </Col>
                    <Col xs={12} md={6}> </Col>
                </Row>
            </Container>
        </Row>
        <Row className='m-l-0 m-r-0 p-l-0 p-r-0'>
            <Container fluid>
                <Row>
                <Products />
                </Row>
            </Container>
        </Row>
       

    </>)
}

export default News;