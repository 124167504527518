import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CustomCss/CustomStyle.css";
import {Row} from "react-bootstrap";
export default function NauAppRegistration(){
    return(
        <>
        <Row classNmae='m-r-0 m-l-0'>
        <iframe title="Nau App Registation" src="https://nauserver.com/app_registration.aspx?id=XBCGSDTWV129376GHGFJS6" style={{width:"100%",height:"1200px",margin:"0",padding:"0"}}></iframe>
        </Row>
        
        </>
    )
}