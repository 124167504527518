import React from "react";
import { Helmet } from 'react-helmet-async';
function SEO({title, description, name, type, keywords}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description}  data-rh="true"/>
<meta name='keywords' content={keywords}  data-rh="true"/>
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type}  data-rh="true"/>
<meta property="og:title" content={title}  data-rh="true"/>
<meta property="og:description" content={description}  data-rh="true"/>
<meta property="og:keywords" content={keywords}  data-rh="true"/>
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name}  data-rh="true"/>
<meta name="twitter:card" content={type}  data-rh="true"/>
<meta name="twitter:title" content={title}  data-rh="true"/>
<meta name="twitter:description" content={description}  data-rh="true"/>
<meta name="twitter:keywords" content={keywords} data-rh="true" />
{ /* End Twitter tags */ }
</Helmet>
)

}
SEO.defaultProps={
    name:"Orion Marine Concepts",
    type:"Maritime ERP",
    keywords:"Marine Software solutions"
}
export default SEO;