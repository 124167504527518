import 'bootstrap/dist/css/bootstrap.min.css';
function Products() {

    return (
        <>
            <div className='row'>
                <div className='container-fluid'>

                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className='ResponsiveImage'>
                            <img src='https://www.orionmarineconcepts.com/wp-content/uploads/2014/03/VESSEL-MANAGEMENT-SOFTWARE1.jpg' alt='Products' className='img-responsive' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Products;