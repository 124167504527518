import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/fontawesome-svg-core'
import { } from '@fortawesome/fontawesome-free-solid';
import { } from '@fortawesome/fontawesome-free';
import { motion } from 'framer-motion';
import { } from 'framer-motion';
import Lottie from "lottie-react";
import VrBox1 from "../Images/NN/vr-box.json";
import VrBox2 from "../Images/NN/VR.json";
import EDMSpost from '../Images/4.png';
import VrFeature1 from '../Images/NN/cross-platform_9872430.png';
import VrFeature2 from '../Images/NN/initiative_9746219.png';
import VrFeature3 from '../Images/NN/schedule_9759915.png';
import VrFeature4 from '../Images/NN/virtual-reality_8654914.png';
import VrFeature5 from '../Images/NN/virtual-reality_8941062.png';
import VrFeature6 from '../Images/NN/team_2379226.png';
import VrFeature7 from '../Images/NN/entertainment_6804481.png';
import CrossPattern from '../Images/Nau/CrossPattern.jpg';
import Vr1Img from '../Images/NN/VR1.png';
import Vr2Img from '../Images/NN/VR2.png';
import Vr3Img from '../Images/NN/VR3.png';

import SEO from '../Components/Seo/Seo';
import { HelmetProvider } from 'react-helmet-async';
export default function OrionVr(index) {
    let boxVariantsL = {};
    //let boxVariantsR = {};
    let boxVariantsPop = {};
    const isMobile = window.innerWidth < 1100; //Add the width you want to check for here (now 768px)
    if (!isMobile) {
        boxVariantsL = {
            initial: { opacity: 0, x: index % 2 === 0 ? 350 : -350 },
            whileInView: { opacity: 1, x: 0, transition: { duration: 0.7 } }
        }
        //   boxVariantsR = {
        //     initial: { opacity: 0, x: index % 2 === 0 ? -350 : 350 },
        //     whileInView: { opacity: 1, x: 0, transition: { duration: 0.7 } }
        //   }
        boxVariantsPop = {
            initial: { opacity: 0, scale: 0.2 },
            whileInView: { opacity: 1, scale: 1, transition: { duration: 1 } }
        }
    }
    else {
        boxVariantsL = {
            initial: { opacity: 1 },
            whileInView: { opacity: 1 }
        }
        //   boxVariantsR = {
        //     initial: { opacity: 1 },
        //     whileInView: { opacity: 1 }
        //   }
        boxVariantsPop = {
            initial: { opacity: 1 },
            whileInView: { opacity: 1 }
        }
    }

    return (
        <>
            <HelmetProvider>
                <SEO
                    description="Explore NAU 2.1, the cutting-edge maritime ERP software designed to enhance safety, streamline crew management, and optimize fleet performance. Our solution offers intelligent modules for safety documentation, audits, crew selection, defect reporting, certificate management, risk assessment, and more. Elevate your maritime operations with NAU 2.1's centralized filing system, administration control, and seamless integration with third-party services. Unlock efficiency, transparency, and reliability in your maritime endeavors. Orion Marine Concepts is one of the leading Maritime Software Companies, offering Marine Software Solutions, Marine Waste Management System, VDR Analysis & Assessment Software"
                    title="Orion Marine Concepts | Neptunes Nest"
                    keywords="Maritime ERP, Ship Management Software, Maritime Compliance, Fleet Management, Business Intelligence, Green Shipping SolutionsMaritime ERP, Cloud-Based Software, Safety Management, Crew Optimization, Fleet Performance, Certificate Management, Risk Assessment, Centralized Filing System, Third-Party Integration, NAU 2.1. Marine Software solutions, Electronic Marpol Seal Log Marine, Waste Management Systems, Vdr Analysis and Assesment Software, Maritime Software, Maritime Software Solutions, Maritime Software Companies" />
            </HelmetProvider>


            <Row className='m-l-0 m-r-0 p-l-0 p-r-0'>
                <Container fluid className="p-l-0 p-r-0 backgroundTtachment">
                    <Row className='m-l-0 m-r-0 motion-container'>
                        <Col xs={12} md={6} className="pt-5">
                            <motion.div variants={boxVariantsL} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                                <h1 className="BiEmpowering" style={{ color: "rgb(6 23 68)" }}>Introducing Neptune's Nest VR by Orion Marine Concepts</h1>
                                <h4 className="BiEmpoweringBottomText" style={{ color: "rgb(6 23 68)" }}>Navigate the Waves in Virtual Reality! </h4>

                            </motion.div>
                        </Col>
                        <Col xs={12} md={6}>
                            <Lottie animationData={VrBox1} loop={true} />
                        </Col>
                    </Row>
                </Container>
            </Row>



            <Row className='m-l-0 m-r-0 p-l-0 p-r-0'>
                <Container>

                    <Row>
                        <Col xs={12} className='m-t-40 m-b-40 text-center'>
                            <h1 className='un-leash-command-heading'>
                                Why Neptune's Nest VR?
                            </h1>
                        </Col>

                        <Col xs={12} md={6}>
                            <Lottie animationData={VrBox2} loop={true} />
                        </Col>
                        <Col xs={12} md={6}>
                            <h2 className="font-bold mb-5 mt-5 text-left">NEPTUNE'S NEST VIRTUAL REAL</h2>
                            <h4 className="text-left mb-3">Neptune's Nest VR goes beyond a simulator – it's a voyage into unparalleled realism and training precision. Whether you're a maritime professional or an enthusiast seeking adventure, our Neptunes Nest is your gateway to mastering the art of navigation.</h4>
                            <h4 className="text-left mb-5">Embark on a virtual journey with Neptune's Nest VR. Ready the anchor, set the course, and navigate the waves like never before!</h4>
                            <h4 className="text-left font-bold">⚓ Your maritime adventure begins here! ⚓</h4>
                        </Col>
                    </Row>
                </Container>
            </Row>


            <Row className='m-l-0 m-r-0 p-l-0 p-r-0' style={{ backgroundImage: `url(${EDMSpost})` }}>
                <Container>

                    <Row>
                        <Col xs={12} className='text-left m-t-100'>


                            <motion.div variants={boxVariantsPop} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                                <h4 style={{ color: "rgb(6 23 68)", fontWeight: "bold", fontSize: "1.7rem" }}>🌟 Key Features 🌟</h4>
                                <div className="features1_component-copy text-left m-t-10 motion-container">

                                    <div className="features-item-1">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature1} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Cross Compatibility</h3>
                                                </div>
                                                <p className="paragraph-11">
                                                    Neptune's Nest VR supports Oculus, Meta Quest, and various VR platforms, ensuring accessibility for all maritime enthusiasts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-2">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature2} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Skill Refinement</h3>
                                                </div>
                                                <p className="paragraph-11">Designed for novice and experienced navigators, our simulator offers a dynamic environment to practice ship maneuvering, honing your skills for real-world scenarios.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-3">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature3} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Holiday  Maintenance</h3>
                                                </div>
                                                <p className="paragraph-11">

                                                    Even on vacation, keep your maritime gear well-oiled. Neptune's Nest VR provides a realistic and enjoyable way to connect with your passion for ships.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-4">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature4} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Single Player Thrills</h3>
                                                </div>
                                                <p className="paragraph-11">
                                                    Dive into the excitement with our single-player free boat racing and standalone single-player modes, offering endless entertainment and challenges.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-5">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature5} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Trainer Module</h3>
                                                </div>
                                                <p className="paragraph-11">
                                                    Elevate your training with our advanced trainer module. Create custom rooms, set traffic, water swell, and incorporate route rules of TSS 1 or TSS 2. Rain, Fog, Day, and night modes add an extra layer of realism to your training sessions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-6">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature6} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Multiplayer Excellence</h3>
                                                </div>
                                                <p className="paragraph-11">
                                                    Connect with fellow enthusiasts in multiplayer mode. Trainers can host servers, allowing up to 4 players to join. Collaborate with 2 captains and 2 helm-men for an immersive team experience. Day and night modes intensify the challenge.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-item-7">
                                        <div className="layout11_item">
                                            <div className="layout11_icon-wrapper"><img src={VrFeature7} loading="lazy" alt="" /></div>
                                            <div className="layout11_content-wrapper">
                                                <div className="margin-bottom margin-small">
                                                    <h3 className="heading-small">Office Premises Training</h3>
                                                </div>
                                                <p className="paragraph-11">
                                                    Enhance your team's skills with the convenience of office-based training. Install one system on your premises for refresher courses and continuous skill development.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </motion.div>
                        </Col>

                        <Col xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <h1 className='un-leash-green-subheading mt-5 mb-3' style={{fontSize:"1.9rem"}}>
                                        Comprehensive Training and Skill Refinement with Neptunes Nest for Aspiring Mariners
                                    </h1>
                                    
                                </Col>
                                <Col xs={12} md={4}>
                                        <Col className="p-r-5 p-l-5">
                                            <Row className="text-start row mt-3 mb-3 card p-b-15 p-t-15">
                                                <Col xs={12} className="m-b-20">
                                                    <img src={Vr1Img} alt="" className="img-fluid BlogIm" />
                                                </Col>
                                                <Col xs={12}>
                                                    <h5 className="font-bold">Immersive Experience</h5>
                                                   
                                                    <p>Orion's Neptunes Nest offer an immersive experience that allows users to virtually step onto a ship and explore its various components. This realistic environment can be used for entertainment, educational purposes, or to give users a firsthand feel of life at sea without actually being on the water.</p>
                                                   
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>


                                    <Col xs={12} md={4}>
                                        <Col className="p-r-5 p-l-5">
                                            <Row className="text-start row mt-3 mb-3 card p-b-15 p-t-15">
                                                <Col xs={12} className="m-b-20">
                                                    <img src={Vr2Img} alt="" className="img-fluid BlogIm" />
                                                </Col>
                                                <Col xs={12}>
                                                    <h5 className="font-bold">Navigation and Route Planning</h5>
                                                   
                                                    <p>  Maritime professionals can utilize VR Simulators for detailed navigation and route planning. By simulating different sea routes, they can assess potential hazards, optimize travel paths, and improve overall voyage efficiency and safety.</p>
                                                   
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                             

                                    <Col xs={12} md={4}>
                                        <Col className="p-r-5 p-l-5">
                                            <Row className="text-start row mt-3 mb-3 card p-b-15 p-t-15">
                                                <Col xs={12} className="m-b-20">
                                                    <img src={Vr3Img} alt="" className="img-fluid BlogIm" />
                                                </Col>
                                                <Col xs={12}>
                                                    <h5 className="font-bold">Realistic Operational Training</h5>
                                                 
                                                    <p>  Neptunes Nest provide a highly realistic platform for teaching new recruits the intricacies of operating a ship. This includes hands-on experience with navigation, engine controls, communication systems, and safety protocols, ensuring they understand the practical aspects of running a ship.</p>
                                                   
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                  
                                    
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Row>
            <Row className='m-l-0 m-r-0 p-l-0 p-r-0'>
                <Container fluid style={{ padding: '0' }} className=' m-t-60 text-left'>


                    <Row className=' pt-5 pb-5 text-center' style={{ backgroundImage: "url(" + CrossPattern + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "0", margin: "0" }}>
                        <Col xs={12} md={8}>
                            <h1 className="BiEmpowering" style={{ color: "#ffffff", paddingTop: "3%" }}>Ready to Sail? </h1>
                            <h1 className="BiEmpoweringBottomText" style={{ color: "#ffffff", paddingTop: "3%" }}>Set sail on a journey that redefines efficiency and ushers in a new era in maritime management. Join the NAU 2.1 wave today. </h1>
                        </Col>
                        <Col xs={12} md={4}>
                            <Button variant="light" className='m-t-90 p-l-50 p-r-50 fs-25 p-t-10 p-b-10 btn btn-light'>
                                <Link to='/ScheduleDemo' style={{ fontSize: "1.4rem" }}>
                                    Embark Now
                                </Link>
                            </Button>
                        </Col>

                    </Row>

                </Container>
            </Row>

        </>
    )
}