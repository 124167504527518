// src/App.js
import React from 'react';
import './App.css';
import Header from './Header';
import { Route, Routes } from 'react-router-dom';
// import CookieConsentBanner from './Components/CookieConsentBanner';
import ScrollToTop from "./Components/ScrollToTop";
import News from './Pages/News';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Products from './Pages/Products';
import VesselManagement from './Pages/VesselManagement';
import ContactUsForm from './Pages/ContactUs';
import FooterComponent from './Footer';
import ProductDetail from './Components/BlogComponents/ProductDetail';
import NauServer from './Pages/Nau2-1';

// Product Pages
import EDMS from './Products/ElectronicDocumentManagementSystem';
import VCertificate from './Products/VCMS';
import ECloud from './Products/Ecloud';
import ShipCrew from './Products/ShipCrewManagement';
import PerformanceMonitoring from './Products/PerformanceMonitoringSoftware';
import PMSInventory from './Products/PurchaseProcurementMaintenance';
import OInvent from './Products/InventoryManagementSystem';
import QHSSE from './Products/RiskManagementSystem';
import Marpole from './Products/ElectronicMarpolSealLogbook';
import WasteDisposal from './Products/ElectronicWasteDisposalLogBook';
import VoyageDataRecorder from './Products/VoyageDataInformation';
import FPMApp from './Products/Fleet PerformanceMobileApp';
import ScheduleDemo from './Pages/ScheduleDemo';
import UnderCounstruction from './Pages/ComingSoon';
import OurTeam from './Pages/OurPeople';
import NauAppRegistration from './Pages/nau_app_registration';
import TypeApproved from './Pages/type-approved';
import OrionPowerBi from './Pages/OrionBi';
import OrionVr from './Pages/NeptunesNest';
import PageNotFound from './Pages/PageNotFound';
import ScrollButton from './Components/ScrollTopButton';

function App() {
  return (
    <div className="App">
      {/* <CookieConsentBanner /> */}
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/vesselManagement' element={<VesselManagement />} />
        <Route path='/product' element={<Products />} />
        <Route path='/products/Electronic-Document-Management-System' element={<EDMS />} />
        <Route path='/products/Vessel-Certificate-Management-System' element={<VCertificate />} />
        <Route path='/products/ECloud' element={<ECloud />} />
        <Route path='/products/Crew-Management-System' element={<ShipCrew />} />
        <Route path='/products/fleet-management' element={<PerformanceMonitoring />} />
        <Route path='/products/Planned-Maintenance-System' element={<PMSInventory />} />
        <Route path='/products/supply-Chain' element={<OInvent />} />
        <Route path='/products/QHSSE' element={<QHSSE />} />
        <Route path='/products/Electronic-Marpol-Seal-Log-book' element={<Marpole />} />
        <Route path='/products/Electronic-Waste-log-Book' element={<WasteDisposal />} />
        <Route path='/products/Voyage-Data-Recorder' element={<VoyageDataRecorder />} />
        <Route path='/products/Fleet-Performance-Mobile-App' element={<FPMApp />} />
        <Route path='/News' element={<News />} />
        <Route path='/News/:productId' element={<ProductDetail />} />
        <Route path='/contact' element={<ContactUsForm />} />
        <Route path='/NauServer' element={<NauServer />} />
        <Route path='/ScheduleDemo' element={<ScheduleDemo />} />
        <Route path='/UnderCounstruction' element={<UnderCounstruction />} />
        <Route path='/OurTeam' element={<OurTeam />} />
        <Route path='/nau_app_registration' element={<NauAppRegistration />} />
        <Route path='/type-approved' element={<TypeApproved />} />
        <Route path='/Orion-Bi' element={<OrionPowerBi />} />
        <Route path='/Neptunes-Nest' element={<OrionVr />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <FooterComponent />
      <ScrollToTop />
      <ScrollButton />
    </div>
  );
}

export default App;
