import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../CustomCss/CustomStyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col } from 'react-bootstrap';
import Blt from '../Images/ClientLogo/Blt.png'
import Thome from '../Images/ClientLogo/Thome.png'
import AQ from '../Images/ClientLogo/AQ.png'
import Arena from '../Images/ClientLogo/Arena.png'
import Asian from '../Images/ClientLogo/Asian.png'
import Bumiarmada from '../Images/ClientLogo/Bumiarmada.png'
import Darya from '../Images/ClientLogo/Darya.png'
import Greateastern from '../Images/ClientLogo/Greateastern.png'
import Karco from '../Images/ClientLogo/Karco.png'
import Krishnan2 from '../Images/ClientLogo/Krishnan2.png'
import Mideast from '../Images/ClientLogo/Mideast.png'
import Osmc from '../Images/ClientLogo/Osmc.png'
import Sandigan from '../Images/ClientLogo/Sandigan.png'
import Uacc from '../Images/ClientLogo/Uacc.jpg'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items:5,
        swipeable:false,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
        slidesToSlide: 1,
       // draggable:true // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 124 },
        items: 2,
        slidesToSlide: 1,
        //draggable:true // optional, default to 1.
    }
};

const ClientList=[
    {
        id:1,
        Clientlogo: Blt
    },
    {
        id:2,
        Clientlogo: Thome
    },
    {
        id:3,
        Clientlogo: AQ
    },
    {
        id:4,
        Clientlogo: Arena
    },
    {
        id:5,
        Clientlogo: Bumiarmada
    },
    {
        id:6,
        Clientlogo: Darya
    },
    {
        id:7,
        Clientlogo: Asian
    },{
        id:8,
        Clientlogo: Greateastern
    },
    {
        id:9,
        Clientlogo: Karco
    },
    {
        id:10,
        Clientlogo: Krishnan2
    },
    {
        id:11,
        Clientlogo: Mideast
    },
    {
        id:12,
        Clientlogo: Sandigan
    },
    {
        id:13,
        Clientlogo: Osmc
    },
    {
        id:14,
        Clientlogo: Uacc
    },
]
function ClientListComponent(){

return(
    <>
    <Container className='mb-3'>
    <Row>
        <Col xs={12} className="OurClients">
    
            <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={1000}
                keyBoardControl={false}
                transitionDuration={1000}
                containerClass="carousel-container"
                //removeArrowOnDeviceType={["tablet", "mobile"]}
                ssr={false}
                swipeable={false}
                draggable={false}
                showDots={false}
                infinite={true}
                partialVisible={false}
                arrows={false}

            >
{ClientList.map(clientlist=>(
      <div className='m-r-10' key={clientlist.id}>
       <img src={clientlist.Clientlogo} alt={clientlist.Clientlogo} className="img-fluid"/>
      </div>
      ))}

            </Carousel>
           
    
        
      </Col>
      </Row>
      </Container>
    </>
)


}

export default ClientListComponent;