import React from "react"
import { useParams } from "react-router-dom"
import productsData from "./productsData"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'

function ProductDetail() {
    const { productId } = useParams()
    const thisProduct = productsData.find(prod => prod.dataHead.replace(/\s/g, "-").toLowerCase() === productId)

    return (
        <Container >
            <Row className="m-r-0 m-l-0 m-t-100">
                <h3 className="font-bold m-b-50">{thisProduct.dataHead}</h3>

                <Col xs={12} md={6} className="p-l-0 p-r-0 m-b-20">
                    <img src={thisProduct.img} alt={thisProduct.dataDate} className="img-fluid BlogProd" />
                </Col>
                <Col xs={12} md={6} className="p-l-0 p-r-0 m-b-20 text-left">
                    <Container>
                        <strong className="text-start" style={{ color: "#009688" }}>Posted at {thisProduct.dataDate}</strong>
                        <p className="text-start" style={{ whiteSpace: "pre-line" }}>{thisProduct.data}</p>
                    </Container>

                </Col>


            </Row>
        </Container>
    );
}

export default ProductDetail