import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CustomCss/CustomStyle.css';
import '../CustomCss/Margins.css';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip, faAnchor, faWater, faDharmachakra } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/fontawesome-svg-core'
import { } from '@fortawesome/fontawesome-free-solid';
import { } from '@fortawesome/fontawesome-free';
import { motion } from 'framer-motion';
import SEO from '../Components/Seo/Seo';
import { HelmetProvider } from 'react-helmet-async';
import OrionTeam from '../Images/Ourteam/OurTeamPhoto.jpg';
import Ceo from '../Images/Ourteam/CptM.jpg'
import mt1 from '../Images/Ourteam/team/6.jpg'
// import mt2 from '../Images/Ourteam/team/8.jpg'
import mt3 from '../Images/Ourteam/team/21.jpg'
import mt4 from '../Images/Ourteam/team/30.jpg'
import mt5 from '../Images/Ourteam/team/23.jpg'
import mt6 from '../Images/Ourteam/team/27.jpg'
import mtDev1 from '../Images/Ourteam/team/31.jpg'
import mtDev2 from '../Images/Ourteam/team/24.jpg'
import mtDev3 from '../Images/Ourteam/team/15.jpg'
import mtDev4 from '../Images/Ourteam/team/11.jpg'
import mtDev5 from '../Images/Ourteam/team/17.jpg'
// import mtDev6 from '../Images/Ourteam/team/13.jpg'
import mtDev7 from '../Images/Ourteam/team/22.jpg'
import mtDev8 from '../Images/Ourteam/team/18.jpg'
import mtDev9 from '../Images/Ourteam/team/26.jpg'
import mtSupp1 from '../Images/Ourteam/team/12.jpg';
import mtSupp2 from '../Images/Ourteam/team/2.jpg';
import mtSupp3 from '../Images/Ourteam/team/5.jpg';
import mtSupp4 from '../Images/Ourteam/team/16.jpg';
import mtSupp5 from '../Images/Ourteam/team/20.jpg';
import mtSupp6 from '../Images/Ourteam/team/9.jpg';
export default function OurTeam(index) {

    let boxVariantsL = {};
    let boxVariantsR = {};
    let boxVariantsPop = {};
    const isMobile = window.innerWidth < 1100;
    if (!isMobile) {
        boxVariantsL = {
            initial: { opacity: 0, x: index % 2 === 0 ? 350 : -350 },
            whileInView: { opacity: 1, x: 0, transition: { duration: 0.7 } }
        }
        boxVariantsR = {
            initial: { opacity: 0, x: index % 2 === 0 ? -350 : 350 },
            whileInView: { opacity: 1, x: 0, transition: { duration: 0.7 } }
        }
        boxVariantsPop = {
            initial: { opacity: 0, scale: 0.2 },
            whileInView: { opacity: 1, scale: 1, transition: { duration: 1 } }
        }
    }
    else {
        boxVariantsL = {
            initial: { opacity: 1 },
            whileInView: { opacity: 1 }
        }
        boxVariantsR = {
            initial: { opacity: 1 },
            whileInView: { opacity: 1 }
        }
        boxVariantsPop = {
            initial: { opacity: 1 },
            whileInView: { opacity: 1 }
        }
    }


    return (

        <>

            <HelmetProvider>
                <SEO
                    description="Explore NAU 2.1, the cutting-edge maritime ERP software designed to enhance safety, streamline crew management, and optimize fleet performance. Our solution offers intelligent modules for safety documentation, audits, crew selection, defect reporting, certificate management, risk assessment, and more. Elevate your maritime operations with NAU 2.1's centralized filing system, administration control, and seamless integration with third-party services. Unlock efficiency, transparency, and reliability in your maritime endeavors. Orion Marine Concepts is one of the leading Maritime Software Companies, offering Marine Software Solutions, Marine Waste Management System, VDR Analysis & Assessment Software"
                    title="Orion Marine Concepts | Our People"
                    keywords="Maritime ERP, Ship Management Software, Maritime Compliance, Fleet Management, Business Intelligence, Green Shipping SolutionsMaritime ERP, Cloud-Based Software, Safety Management, Crew Optimization, Fleet Performance, Certificate Management, Risk Assessment, Centralized Filing System, Third-Party Integration, NAU 2.1. Marine Software solutions, Electronic Marpol Seal Log Marine, Waste Management Systems, Vdr Analysis and Assesment Software, Maritime Software, Maritime Software Solutions, Maritime Software Companies" />
            </HelmetProvider>
            <Row className="m-l-0 m-r-0">
                <Container fluid className="p-r-0 p-l-0 backgroundTtachment m-b-120" style={{ backgroundImage: `url(${OrionTeam})`, backgroundPosition: "center center", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
                    <Col>
                        <h1 className="fs-50 p-t-30">MEET THE TEAM</h1>
                    </Col>
                </Container>
                <Container className="motion-container">
                    <Row>
                        <Col md={1}></Col>
                        <Col xs={12} md={5} className="motion-container">
                            <motion.div variants={boxVariantsL} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                                <div className="col-12 text-left">
                                    <h4 style={{ color: "#5864FF", fontWeight: '600', marginBottom: '20px' }}>MEET OUR DIRECTOR</h4>
                                    <h2 style={{ fontWeight: '600', marginBottom: '20px' }}>CAPTAIN MOHIT SABHARWAL</h2>
                                    <p className="m-b-20" style={{ color: "#666666", letterSpacing: "1px", lineHeight: "1.8" }}>Captain Mohit's journey began on the waves, rising from a young cadet to a seasoned Master Mariner. His three decades which included navigating the oceans and being part of the Maritime Fraternity instilled a deep understanding of seafaring and a desire to push boundaries.</p>
                                    <ul className="elementor-icon-list-items">
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <FontAwesomeIcon icon={faWater} />
                                            </span>
                                            <span className="elementor-icon-list-text">This yearning led him to found Orion Marine Concepts in 2006, leveraging his expertise to pave the way for innovative solutions. Driven by a thirst for knowledge, he pursued an MBA in Ship Management and certifications in quality management, building a unique blend of operational acumen and dedication to excellence.</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <FontAwesomeIcon icon={faAnchor} />
                                            </span>
                                            <span className="elementor-icon-list-text">In 2009, his vision materialised with the birth of Orion Technology division. This company embodies his belief in seamlessly blending maritime expertise with cutting-edge technology to exceed client expectations and lead the industry.</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <FontAwesomeIcon icon={faDharmachakra} />
                                            </span>
                                            <span className="elementor-icon-list-text">Today, Capt. Mohit continues to captain Orion's journey, drawing upon his diverse experiences to champion innovation. He actively explores integrating technologies like augmented reality and AI, shaping a more efficient and sustainable future for the maritime industry.</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <FontAwesomeIcon icon={faShip} />
                                            </span>
                                            <span className="elementor-icon-list-text">Our Captain's story is a testament to the power of vision, continuous learning, and a relentless pursuit of progress. It serves as an inspiration to aspiring professionals, demonstrating that the possibilities within the vast seas of the maritime industry are boundless.</span>
                                        </li>
                                    </ul>
                                </div>
                            </motion.div>
                        </Col>
                        <Col xs={12} md={5} className="motion-container">
                            <motion.div variants={boxVariantsR} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                                <div className="col-12" style={{ borderRadius: "25px", overflow: "hidden" }}>
                                    <img alt='orion ceo' src={Ceo} className="img-fluid" />
                                </div>
                            </motion.div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Container>
                <Container className="m-t-120 motion-container">
                    <motion.div variants={boxVariantsPop} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                        <Row>
                            <h1 className="m-b-40 font-bold">TEAM ORION MARINE CONCEPTS</h1>
                            <h5 className="m-b-40" style={{ lineHeight: "1.8" }}>
                                At Orion Marine Concepts, we take pride in the diversity and expertise of our talented team.<br />
                                Together, we are driven by a shared commitment to excellence, innovation, and client success.
                            </h5>
                            <h3 className="m-b-50 font-bold">OUR MANAGEMENT</h3>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt1} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">MAMTA KANYAL</h3>
                                    <div className="wpr-member-job">Senior Manager Projects & Operations</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            {/* <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt2} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">SONI DANGI</h3>
                                    <div className="wpr-member-job">HR Manager</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col> */}
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt3} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">AVNISH MISHRA</h3>
                                    <div className="wpr-member-job">Project Manager</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt4} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">JAI KISHAN KUMAR</h3>
                                    <div className="wpr-member-job">Project Coach</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt5} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">VINOD KUMAR</h3>
                                    <div className="wpr-member-job">Software Engineering Manager</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mt6} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">KANISHKA AGARWAL</h3>
                                    <div className="wpr-member-job">Social Media Manager</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                    <motion.div variants={boxVariantsPop} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                        <Row className="m-t-50">
                            <h2 className="m-b-50 font-bold">MEET OUR DEVS</h2>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev2} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">AKHILESH PANDEY</h3>
                                    <div className="wpr-member-job">Senior Software Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev3} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">MURLI KUMAR</h3>
                                    <div className="wpr-member-job">Senior UI Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev4} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">KRITESH POKHRIYAL</h3>
                                    <div className="wpr-member-job">Senior Software Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev5} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">PRAMENDU THAKUR</h3>
                                    <div className="wpr-member-job">Software Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev7} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">DEEPANSHU YADAV</h3>
                                    <div className="wpr-member-job">Software Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            {/* <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev6} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">PRINCE SHARMA</h3>
                                    <div className="wpr-member-job">System Test Engineer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col> */}
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev8} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">SAGAR BISHNOI</h3>
                                    <div className="wpr-member-job">Business Intelligence Analyst</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev9} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">SAKSHI SHARMA</h3>
                                    <div className="wpr-member-job">Jr. Developer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                    <motion.div variants={boxVariantsPop} whileInView="whileInView" initial="initial" viewport={{ once: true }} >
                        <Row className="m-t-50">
                            <h2 className="m-b-50 font-bold">OUR IMPLEMENTATION, SUPPORT & DEPLOYMENT</h2>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtDev1} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">HARISH SHET</h3>
                                    <div className="wpr-member-job">Project Lead</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp1} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">SHRISTI KARWASARA</h3>
                                    <div className="wpr-member-job">Support Team Lead</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp2} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">ASTHA BHAMBU</h3>
                                    <div className="wpr-member-job">Senior Implementation Engineer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp3} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">RIYA BHAMBU</h3>
                                    <div className="wpr-member-job">Key Accounts Executive</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp4} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">MEHUL SHARMA</h3>
                                    <div className="wpr-member-job">Implementation Engineer</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp5} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">AKASH MISHRA</h3>
                                    <div className="wpr-member-job">Technical Support Executive</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={mtSupp6} alt="orion team member" className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">KHUSHBOO SINGH</h3>
                                    <div className="wpr-member-job">Assistant Executive</div>
                                    <div className="wpr-member-divider"></div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </Container>
            </Row >
        </>
    )
}