import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileAlt, faFireExtinguisher, faBuilding } from '@fortawesome/fontawesome-free-solid';
import { faMagnifyingGlass, faBook, faShip, faAnchorCircleCheck } from "@fortawesome/free-solid-svg-icons";
import VesselBg from '../Images/VesselBg.jpg';

function VesselManagement() {

    return (
        <>
            <Container fluid style={{ padding: '0' }}>
                <Row className='mb-5' style={{ backgroundColor: '#f9f9f9', margin: '0', padding: '0' }}>
                    <div className='col-12' style={{ padding: "0" }}>

                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                            <div className='ResponsiveImage' style={{ position: "relative" }}>
                                <img src={VesselBg} alt='Products' className='img-fluid' />
                                <div className='PositionedText'>
                                    <h3>VESSEL MANAGEMENT SOFTWARE</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className='mb-5' style={{ margin: '0', padding: '0' }}>
                    <Container>
                        <h3 className='mt-4 mb-5'>
                            WHAT WE DO
                        </h3>
                        <h5>Over the years, Orion has built a team of personnel with expertise in mixed disciplines, using a mix of staff with long years of practical ship-board and ship-yard experience.</h5>
                        <Row className="g-5 mt-5 mb-5 text-start">
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>Shipboard Surveys & Inspections</p>
                                        <p>Reviewing Safety Management Systems for shore Office and Vessels to Industry standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faEye} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>SHIPBOARD SURVEYS & INSPECTIONS</p>
                                        <p>All operational aspects of LNG Vessels including refit preparation as well as quality & quantity surveys.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faFileAlt} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>SHIPBOARD TRAINING & PRE-VETTING</p>
                                        <p>We provide well structured onboard training to the Ship Staff assisting them to enhance their performance in this highly competitive industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faBook} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>MANUALS AND PLANS</p>
                                        <p>Ballast Water Management Plan, Shipboard Marine Pollution Emergency Plan, Cargo Securing Manual, Ship Plans Digitization, ISPS Plan, Emergency Towing arrangement Manual & Electronic SMS manuals.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faFireExtinguisher} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>RISK MANAGEMENT STUDIES</p>
                                        <p>The risk assessment is carried out using a holistic approach that goes beyond classification society and statutory compliance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faShip} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>VESSEL CONDITION ASSESSMENT</p>
                                        <p>Pre-purchase vessel inspections, Pre chartering assessments and survey & On / Off Hire Vessel Condition Surveys.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faAnchorCircleCheck} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>PORTS & HARBOURS</p>
                                        <p>We provide overall planning for port expansions and new port development from dry & liquid bulk cargo terminals, general cargo ports to container ports, fishing ports, cruise terminals, marinas, Ro-Ro and ferry ports.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className='row justify-content-start'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon icon={faBuilding} size="2x" color='#0395ed' />
                                    </div>
                                    <div className='col-10' style={{ padding: "0" }}>
                                        <p style={{ fontWeight: "500" }}>BUSINESS PLANNING</p>
                                        <p>Strategy Review, Benchmarking, Market Research, Feasibility Studies, Due diligence prior investment, Diversification Studies, Competitor Analysis, Operational Audits & Planning, Manpower Analysis, Project Appraisals & Company reorganization and restructuring</p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );
}

export default VesselManagement;